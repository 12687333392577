import React, { useState } from "react"
import { get } from "lodash"
// import { useWindowSizes } from '@hooks/window'
import { linkResolver } from "../../utils/linkResolver"
import { Strings } from "../../utils/strings"
import { useWindowMeasures } from "@hooks/window"
import Image from "./Image"

const ProductCard = ({ item, index, toggleSampleForm }) => {
  let product = []

  if (item?.related_product) {
    product = item.related_product
  } else {
    product = item
  }

  console.log("[ProducCard.js] item", item)
  // console.log('[ProducCard.js] toggleSampleForm', toggleSampleForm)

  const title = get(product, "title[0].text", "Title placeholder")
  const meta = get(product, "_meta") ?? get(product, "meta")
  const linkUrl = linkResolver(meta)
  const sku = get(product, "sku[0].text", "123-123")
  const fazenda = get(product, "fazenda")
  const fazenda_title = get(fazenda, "title[0].text", "Fazenda name")
  const country_title = get(fazenda, "country.title[0].text", "Country name")
  const country_flag = get(fazenda, "country.flag")
  const main_image = get(product, "main_image")
  const family_image = get(product, "family.illustration_individual")
  // const family = get(product, 'family')
  const notes = get(product, "notes")
  const n_notes = notes?.length - 1
  // const status = get(product, 'status[0].text')
  // const packaging = get(product, 'packaging[0].text')
  // const min_order = get(product, 'min_order')
  const current_location = get(product, "current_location[0].text")
  // const harvest = get(product, 'harvest[0].text')
  const bags_available = get(product, "bags_available")
  // const harvest_year = get(product, 'harvest_year[0].text')
  const processing = get(product, "processing[0].text")
  const variety = get(product, "variety[0].text")
  const details = get(product, "details")
  // const altitude = get(product, 'altitude[0].text')

  // Sample Forms
  const body1 = get(product, "body1")
  const body1Size = body1?.length

  const allStrings = Strings(meta)

  const [orderState, setOrderState] = useState(false)

  const goToProduct = () => {
    // console.log('clickHandle target', target)

    if (orderState === false) {
      window.location.href = linkUrl
    }
  }

  const { width } = useWindowMeasures()
  let margin
  if (width > 1440 && index === 0) {
    margin = (width - 1440) / 2 + 48
  }

  const renderDetail = (item, index) => {
    // console.log("renderDetail item", item)

    const title = get(item, "title1")
    const text = get(item, "text")
    const show_in_mini_card = get(item, "show_in_mini_card")

    if (!show_in_mini_card) return null

    return (
      <div className="product_card__details__item" key={index}>
        <label>{title}</label>
        <p className="p-medium font-book">{text}</p>
      </div>
    )
  }

  return (
    <div
      className={"product_card"}
      key={index}
      role="button"
      tabIndex="0"
      onKeyDown={() => goToProduct()}
      onClick={() => goToProduct()}
      style={{ marginLeft: margin, cursor: "pointer" }}
    >
      <div className="product_card__top">
        <img
          className="product_card__top__image"
          src={family_image?.url}
          alt={family_image?.alt}
        />
        <div className="product_card__top__info">
          <div className="country_tag">
            <img
              src={country_flag?.url}
              alt={country_flag?.alt ?? country_title}
            />
            {country_title}
          </div>
          <h5 className="p-large font-book">{fazenda_title}</h5>
        </div>
      </div>

      <div className="product_card__cover">
        {/* {harvest ? 
          <div className="product_card__cover__tag tag">
            {harvest}
          </div>
        : null } */}
        {/* <img src={main_image?.url} alt={main_image?.alt ?? title} /> */}
        <Image image={main_image} width={500} />
      </div>

      <div className="product_card__main_info">
        <h2 className="product_card__main_info__title h3">{title}</h2>
        <div className="product_card__main_info__notes p-small">
          {notes?.map((note, index) => {
            const note_value = get(note, "note[0].text")

            if (n_notes === index) {
              return <span key={index}>{note_value}</span>
            } else {
              return <span key={index}>{note_value}&nbsp;|&nbsp;</span>
            }
          })}
        </div>
        <div className="product_card__main_info__sku p-small">{sku}</div>
      </div>

      {current_location || current_location || processing || bags_available ? (
        <hr />
      ) : (
        ""
      )}

      <div className="product_card__details">
        {/* {variety ? (
          <div className="product_card__details__item">
            <label>{allStrings?.coffee_variety ?? "Variety"}</label>
            <p className="p-medium font-book">{variety}</p>
          </div>
        ) : (
          ""
        )}

        {processing ? (
          <div className="product_card__details__item">
            <label>{allStrings?.coffee_process ?? "Process"}</label>
            <p className="p-medium font-book">{processing}</p>
          </div>
        ) : (
          ""
        )} */}

        {details?.map((item, index) => renderDetail(item, index))}

        {/* {altitude ?
          <div className="product_card__details__item">
            <label>{allStrings?.coffee_altitude ?? 'Altitude'}</label>
            <p className="p-medium font-book">
              {altitude}
            </p>
          </div>
        : ''} */}
        {/* 
        {harvest_year ?
          <div className="product_card__details__item">
            <label>{allStrings?.coffee_harvest_year ?? 'Harvest Year'}</label>
            <p className="p-medium font-book">
              {harvest_year}
            </p>
          </div>
        : ''} */}

        {/* {bags_available ? (
          <div className="product_card__details__item">
            <label>
              {allStrings?.coffee_bags_available ?? "Bags Available"}
            </label>
            <p className="p-medium font-book">{bags_available}</p>
          </div>
        ) : (
          ""
        )} */}

        {/* {current_location ?
          <div className="product_card__details__item">
            <label>{allStrings?.coffee_current_location ?? 'Current Location'}</label>
            <p className="p-medium font-book">
              {current_location}
            </p>
          </div>
        : ''} */}
      </div>

      <div className="product_card__buttons">
        <button
          className="product_card__button btn-secondary white small w-arrow"
          onKeyDown={() => goToProduct()}
          onClick={() => goToProduct()}
        >
          {allStrings?.cards_product_card_button ?? "More info"}
        </button>
        {body1Size > 0 && (
          <button
            className="product_card__button-sample btn-secondary white small w-arrow"
            onClick={() => {
              toggleSampleForm(product)
            }}
            onMouseEnter={() => setOrderState(true)}
            onMouseLeave={() => setOrderState(false)}
          >
            {allStrings?.cards_product_order_sample ?? "Order a Sample"}
          </button>
        )}
      </div>
    </div>
  )
}

export default ProductCard
