import { useLayoutEffect, useState } from 'react'

const screenSizes = [
  { type: 'IphoneSmall', value: 320 },
  { type: 'Iphone', value: 375 },
  // { type: 'IphonePlus', value: 414 },
  { type: 'IphonePlus', value: 675 },
  { type: 'Ipad', value: 812 },
  { type: 'IpadPro', value: 1224 },
  { type: 'IpadLandscape', value: 1366 },
  { type: 'Desktop', value: 1440 },
  { type: 'DesktopWide', value: 1900 }
]

export const useWindowMeasures = () => {
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    const updateSize = () => setSize([window.innerWidth, window.innerHeight])

    window.addEventListener('resize', updateSize)

    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const windowMeasures = { width: size[0], height: size[1] }

  return windowMeasures
}

export const useWindowSizes = () => {
  const { width } = useWindowMeasures()

  let sizes = {}

  screenSizes.forEach((size, index) => {
    if (index < screenSizes.length - 1) {
      sizes[`is${size.type}`] = width <= size.value
    } else {
      sizes[`is${size.type}`] = width > size.value
    }
  })

  return sizes
}

export default useWindowMeasures
