import React, { useEffect, useMemo, useRef, useState } from 'react'
import { get } from 'lodash'
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Layout from '../components/layout'
// import PlpHeader from '../components/slices/PlpHeader'
import PlpFamilyCountry from '../components/slices/PlpFamilyCountry'
import EditorialBanner from '../components/slices/EditorialBanner'
import IlustrationEditorialBanner from '../components/slices/IlustrationEditorialBanner'
// import PlpCoffees from '../components/slices/PlpCoffees'
// import { useQueryParam, NumberParam, StringParam } from "use-query-params"
import PlpFazendas from '../components/slices/PlpFazendas'
import PlpFazendaHeader from '../components/slices/PlpFazendaHeader'

// Display the title, date, and content of the Post
const PlpFazendaBody = ({ page, allFazendas }) => {

  const lang = useMemo(() => (
    get(page, '_meta.lang', 'en-us')
  ), [page])

  // console.log('[plp-fazenda.js] page', page)
    
  // const [sort, setSort] = useQueryParam("sort", StringParam) - This was refresing the page
  const [sort, setSort] = useState("all-fazendas")
  const [showPlpHeaderBar, setShowPlpHeaderBar] = useState('')
  const plpContentElem = useRef()

  // console.log('products', products)

  const changeSort = (sort) => {
    setSort(sort)
    // console.log('[archive.js] handleSelectFilter filter', filter)
  }

  if(sort === "" || sort === undefined || sort === null) {
    setSort("all-fazendas")
  }

  const handleScroll = () => {

    // console.log('body scrollTop', document.body.scrollTop || document.documentElement.scrollTop)
    // console.log('offsetTop', plpContentElem.current.offsetTop)

    // console.log('plpContentElem', plpContentElem)
    if(plpContentElem.current!== null) {
      if(plpContentElem && plpContentElem?.current.offsetTop - 2 < (document.body.scrollTop || document.documentElement.scrollTop)) {
        setShowPlpHeaderBar(' show')
      } else {
        setShowPlpHeaderBar('')
      }
    }
  }

  const parsedFazendas = useMemo(() => {
    if (!allFazendas) {
      return null
    }

    return allFazendas.map((d) => {
      const node = d.node
      // console.log('[PlpFazendas.js] node', node)

      return {
        title: get(node, 'title'),
        _meta: get(node, '_meta'),
        slug: get(node, '_meta.uid'),
        intro: get(node, 'intro'),
        main_image: get(node, 'main_image'),
        local_location: get(node, 'local_location'),
        country: get(node, 'country'),
        // country_uid: get(node, 'country._meta.uid'),
      }
    }).sort((a,b) => (a.slug < b.slug) ? 1 : ((b.slug < a.slug) ? -1 : 0))
  }, [allFazendas])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);   
  }, [])

  let scrollSpyList = []

  const renderContainer = (container, index) => {

    // console.log('container', container.type)

    if(sort === "country") {
      if (container && container.type === "family_products") {
        const country_uid = get(container, 'primary.country._meta.uid')
        if(country_uid && !scrollSpyList[country_uid]) {
          scrollSpyList.push('uid-'+country_uid)
        }
        return (<PlpFamilyCountry key={index} data={container} lang={lang} index={index} />)
      }

      if (container && container.type === "editorial__image___text") {
        if(index && !scrollSpyList[index]) {
          scrollSpyList.push("i-t-"+index)
        }
        return (<EditorialBanner key={index} data={container} index={index} />)
      }
    }

    if (container && container.type === "illustration_banner") {
      if(index && !scrollSpyList[index]) {
        scrollSpyList.push("i-b-"+index)
      }
      return (<IlustrationEditorialBanner key={index} data={container} index={index} />)
    }
    
    // if (container && container.type === "related_case_studies") {
    //   return (<RelatedEtudes key={index} data={container} index={index} />)
    // }

    return []
  }

  if(!page) return null;

  return (
    <section>
      <div className="containerGlobal"> 

        <PlpFazendaHeader 
          page={page} 
          changeSort={changeSort} 
          sort={sort}
          showPlpHeaderBar={showPlpHeaderBar}
          scrollSpyItems={scrollSpyList} />

        <div className="plp_content" ref={plpContentElem}>
          {sort === "all-fazendas" ? 
            <PlpFazendas 
              allFazendas={parsedFazendas} />
          : null}

          {page && page?.body?.map((container, index) => (
            renderContainer(container, index)
          ))}
        </div>

      </div>
    </section>
  )
}

export default (props) => {

  if(!props) return null
  const lang = props?.pathContext?.lang

  const doc = props.data.prismic.allFazenda_listing_pages.edges[0];
  const allFazendas = props.data.prismic.allFazendas.edges.filter(d => d.node._meta.lang === lang);

  if(!doc) return null;

const meta = doc.node._meta

  const seoTitle = useMemo(() => (
    get(doc, 'node.seo_title[0].text')
  ), [doc])

  const seoText = useMemo(() => (
    get(doc, 'node.seo_meta[0].text')
  ), [doc])

  const seoImage = useMemo(() => (
    get(doc, 'node.seo_image.url')
  ), [doc])

  return(

    <Layout pathname={props.path} meta={meta}>
      <SEO title={seoTitle ? seoTitle : "Transparent, Sustainable and Direct"}
        description={seoText}
        image={seoImage}
      />
      { doc && allFazendas ? 
        <PlpFazendaBody page={ doc.node } allFazendas={allFazendas} />
      :
        []
    }
    </Layout>
  )
}

export const query = graphql`
query plpFazendaQuery($lang: String, $uid: String) {
  prismic {
    allFazenda_listing_pages(lang: $lang, uid: $uid) {
      edges {
        node {
          _meta {
            uid
            lang
            alternateLanguages {
              lang
              uid
              type
            }
          }
          seo_title
          seo_meta
          seo_image
          intro
          title
          body {
            ... on PRISMIC_Fazenda_listing_pageBodyFamily_products {
              type
              label
              primary {
                country {
                  ... on PRISMIC_Country {
                    title
                    flag
                    intro
                    flag_outline
                    _meta {
                      uid
                      lang
                    }
                  }
                }
              }
            }
            ... on PRISMIC_Fazenda_listing_pageBodyEditorial__image___text {
              type
              label
              primary {
                title
                text
                image
              }
            }
            ... on PRISMIC_Fazenda_listing_pageBodyIllustration_banner {
              type
              label
              primary {
                title
                text
                illustration
                button_url {
                  ... on PRISMIC__ExternalLink {
                    target
                    _linkType
                    url
                  }
                  ... on PRISMIC__Document {
                    _meta {
                      lang
                      type
                      uid
                    }
                  }
                }
                button_text
              }
            }
          }
        }
      }
    }
    allFazendas {
      edges {
        node {
          _meta {
            uid
            lang
            type
          }
          title
          main_image
          intro
          local_location
          country {
            ... on PRISMIC_Country {
              title
              flag
              _meta {
                uid
                lang
              }
            }
          }
        }
      }
    }
  }
}
`
