import React from 'react'
// import { get } from 'lodash'
// import { useStaticQuery } from 'gatsby'
// import { linkResolver } from '../../utils/linkResolver'

import FazendaCard from './FazendaCard'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'

const PlpFazendas = ({ index, allFazendas }) => {

  // console.log('[PlpFazendas.js] allFazendas', allFazendas)

  

  // console.log('[PlpFazendas.js] allFazendas', allFazendas)


  // console.log('filteredProjects', filteredProjects)

  return(
    <section
      key={index}
      className={"plp_all_coffees "}>

      <div className="list_product_card">

        {allFazendas.map((item, index) => {          
          return (
            <div className="list_product_card__col list_product_card__col-fazenda" key={index}>
              <FazendaCard item={item} index={index} />
            </div>
          )
        })} 

      </div>
      
    </section>
  )  
}

export default PlpFazendas
