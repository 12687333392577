import React, { useMemo, useState } from 'react'
import { get } from 'lodash'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Scrollspy from 'react-scrollspy'
import { Strings } from '../../utils/strings'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'

const PlpFazendaHeader = ({ index, page, changeSort, sort, showPlpHeaderBar, scrollSpyItems }) => {

  const title = useMemo(() => (
    get(page, 'title[0].text', 'title placeholder')
  ), [page])

  const intro = useMemo(() => (
    get(page, 'intro[0].text', 'intro placeholder')
  ), [page])

  const allStrings = Strings(page)

  // Toggle Sort

  const [sortButtonState, setSortButtonState] = useState('')
  const toggleSortButton = () => {
    // setSortButtonState(!sortButtonState)
    if(sortButtonState === '') {
      setSortButtonState(' open')
    } else {
      setSortButtonState('')
    }
  }

  const renderPill = (container, index) => {

    if (container && container.type === "family_products") {
      
      const title = get(container, 'primary.country.title[0].text', 'title placeholder')
      const image_color = get(container, 'primary.country.flag')
      const image_outline = get(container, 'primary.country.flag_outline')
      const uid = get(container, 'primary.country._meta.uid')

      return (
        <button 
          className="btn_pill" 
          key={index} 
          onClick={() => scrollTo('#'+uid)}>
          <span>{title}</span>
          <div className="btn_pill__imgs">
            <img className="btn_pill__imgs__outline" src={image_outline?.url} alt={image_outline?.alt ?? title} />
            <img className="btn_pill__imgs__color" src={image_color?.url} alt={image_color?.alt ?? title} />
          </div>
        </button>
      )
    } 
    
    return null
  }

  return(
    <>
      <section
        key={index}
        className={"plp_header"}>
        
        <div className="row row-justify">
          <div className="col col4 plp_header__col_text">
            <h1 className="h3">{title}</h1>
            <div className="plp_header__info">
              <p className="p-large font-book">
                {intro}
              </p>
            </div>
          
          </div>
          <div className="col col4 plp_header__col_filter">
              
            <div className="plp_header__filter plp_header__filter-half">

              <div 
                className={"btn-dropdown "+sortButtonState} 
                onClick={() => toggleSortButton()}
                onKeyDown={() => toggleSortButton()}
                role="button"
                tabIndex="0">
                <div className="btn-dropdown__name">
                  {allStrings?.flp_sort_by ?? 'Sort by'}
                </div>
                
                <ul className="btn-dropdown__items">

                  <li className={sort === 'country' ? "active" : ''}>
                    <button
                      onClick={() => changeSort('country')}
                      onKeyDown={() => changeSort('country')}>
                      <span className="btn-dropdown__items__square"></span>
                      {allStrings?.flp_country ?? 'Country'}
                    </button>
                  </li>

                  <li className={sort === 'all-fazendas' ? "active" : ''} >
                    <button
                      onClick={() => changeSort('all-fazendas')}
                      onKeyDown={() => changeSort('all-fazendas')}>
                      <span className="btn-dropdown__items__square"></span>
                      {allStrings?.flp_all_fazendas ?? 'All Fazendas'}
                    </button>
                  </li>
                </ul>
                
              </div>

            </div>

            

          </div>
        </div>

        <div className="plp_header__achoors">
          {sort === 'country' ?
            page && page?.body?.map((container, index) => (
              renderPill(container, index)
            ))
          : null}
        </div>

      </section>

      <div className={"plp_header_bar"+showPlpHeaderBar}>
        <div className="plp_header_bar__container">
          <div className="plp_header_bar__bg"></div>
          
          <Scrollspy 
            className="plp_header_bar__achoors"
            currentClassName="active"
            items={scrollSpyItems}>
            
            {sort === 'country' ?
              page && page?.body?.map((container, index) => (
                renderPill(container, index)
              ))
             
            : null}

          </Scrollspy>

          <div className="plp_header_bar__col_filter half">

            <div className="plp_header__filter ">
             
              <div 
                className={"btn-dropdown "+sortButtonState} 
                onClick={() => toggleSortButton()}
                onKeyDown={() => toggleSortButton()}
                role="button"
                tabIndex="0">
                <div className="btn-dropdown__name">
                  {allStrings?.flp_sort_by ?? 'Sort by'}
                </div>
                
                <ul className="btn-dropdown__items">

                  <li className={sort === 'country' ? "active" : ''}>
                    <button
                      onClick={() => changeSort('country')}
                      onKeyDown={() => changeSort('country')}>
                      <span className="btn-dropdown__items__square"></span>
                      {allStrings?.flp_sort_by ?? 'Country'}
                    </button>
                  </li>

                  <li className={sort === 'all-fazendas' ? "active" : ''} >
                    <button
                      onClick={() => changeSort('all-fazendas')}
                      onKeyDown={() => changeSort('all-fazendas')}>
                      <span className="btn-dropdown__items__square"></span>
                      {allStrings?.flp_all_fazendas ?? 'All Fazendas'}
                    </button>
                  </li>
                </ul>

              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  )  
}

export default PlpFazendaHeader
